import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Checkbox, Icon, Input, Pagination, Table, Tag, Tooltip,
} from 'antd';
import UsersService from '../../../services/users.service';
import './index.css';
import { escapeRegExp, parseSearch, stringifySearch } from '../../../utils';

const { Column } = Table;

class Customers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      count: 0,
      users: [],
    };
    this.SearchRef = React.createRef();
  }

  componentDidMount() {
    this.fetch();
    setTimeout(() => {
      this.SearchRef.current.focus();
    });
  }

  componentDidUpdate(prevProps) {
    const prevSearch = parseSearch(prevProps.location.search);
    const currentSearch = parseSearch(this.props.location.search);
    if (
      prevSearch.page !== currentSearch.page
      || prevSearch.limit !== currentSearch.limit
      || prevSearch.$email !== currentSearch.$email
      || prevSearch.$username !== currentSearch.$username
      || prevSearch.$hasPlan !== currentSearch.$hasPlan
    ) {
      this.fetch();
    }
  }

  fetch = () => {
    if (this.state.loading) return;
    this.setState({ loading: true }, () => {
      const {
        page,
        limit,
        $email,
        $hasPlan,
      } = parseSearch(this.props.location.search);
      UsersService
        .list({
          page: parseInt(page || '1', 10) - 1,
          limit: parseInt(limit || '20', 10),
          filter: {
            ...($email ? { email: { $regex: escapeRegExp($email), $options: 'i' } } : {}),
            ...($hasPlan === 'true' ? {
              $or: [
                { plan: { $ne: 'FREE' } },
                { 'stripeSubscription.status': { $in: ['active', 'trialing'] } },
              ],
            } : {}),
          },
        })
        .then(({ users, count }) => {
          this.setState({ loading: false, users, count });
        })
        .catch(() => null);
    });
  };

  onPageChange = (page) => {
    const currentSearch = parseSearch(this.props.location.search);
    const newSearch = { ...currentSearch, page: `${page}` };
    if (JSON.stringify(currentSearch) !== JSON.stringify(newSearch)) {
      this.props.history.push({
        pathname: '/app/users',
        search: stringifySearch(newSearch),
      });
    }
  };

  onPageSizeChange = (a, pageSize) => {
    const currentSearch = parseSearch(this.props.location.search);
    const newSearch = { ...currentSearch, limit: `${pageSize}` };
    if (JSON.stringify(currentSearch) !== JSON.stringify(newSearch)) {
      this.props.history.push({
        pathname: '/app/users',
        search: stringifySearch(newSearch),
      });
    }
  };

  handleSearchField = (email) => {
    const search = parseSearch(this.props.location.search);
    const newSearch = { ...search };
    if (email) {
      newSearch.$email = email;
    } else {
      delete newSearch.$email;
    }
    if (JSON.stringify(newSearch) !== JSON.stringify(search)) {
      delete newSearch.page;
      this.props.history.push({
        pathname: '/app/users',
        search: stringifySearch(newSearch),
      });
    }
  };

  onHasPlanChange = (e) => {
    const search = parseSearch(this.props.location.search);
    const newSearch = { ...search };
    if (e.target.checked) {
      newSearch.$hasPlan = true;
    } else {
      delete newSearch.$hasPlan;
    }
    if (JSON.stringify(newSearch) !== JSON.stringify(search)) {
      delete newSearch.page;
      this.props.history.push({
        pathname: '/app/users',
        search: stringifySearch(newSearch),
      });
    }
  };

  render() {
    const {
      page,
      limit,
      $email,
      $hasPlan,
    } = parseSearch(this.props.location.search);
    return (
      <div className="Container_custom">
        <div className="Customers">
          <div>
            <Input.Search
              ref={this.SearchRef}
              autoFocus
              onSearch={value => this.handleSearchField(value)}
              size="large"
              enterButton="Search"
              className="Input_search_main"
              placeholder="Enter email"
              value={$email}
              onChange={e => this.handleSearchField(e.target.value)}
            />
            <Checkbox
              checked={$hasPlan === 'true'}
              onChange={this.onHasPlanChange}
              style={{
                paddingBottom: 3,
                top: -6,
                position: 'relative',
              }}
            >
              only users with a plan
            </Checkbox>
          </div>
          <Table
            dataSource={this.state.users}
            size="small"
            loading={this.state.loading}
            rowKey={record => record._id}
            pagination={false}
          >
            <Column
              title="Email"
              dataIndex="email"
              key="email"
              render={(email, user) => (
                <>
                  <span style={{ fontSize: 10 }}>{user.emailValidated ? '✅ ' : '❌ '}</span>
                  <a href={`#/app/users/${user._id}`}>{email}</a>
                </>
              )}
            />
            <Column title="Fname" dataIndex="firstName" key="firstName" />
            <Column title="Lname" dataIndex="lastName" key="lastName" />
            <Column title="Company" dataIndex="companyName" key="companyName" />
            <Column title={() => <Icon type="environment" />} dataIndex="ipInfo.country" key="ipInfo.country" />
            <Column
              title="Goal"
              dataIndex="goal"
              key="goal"
              render={goal => (
                goal ? (
                  <Tooltip title={goal} trigger={['hover']}>
                    <span><Icon type="info-circle" /></span>
                  </Tooltip>
                ) : <></>
              )}
            />
            <Column
              title="Plan"
              dataIndex="plan"
              key="plan"
              render={plan => (
                <Tag
                  color={{
                    FREE: 'green',
                    STARTER: 'blue',
                    BUSINESS: 'red',
                    BUSINESS_V2: 'red',
                    GOLD: 'orange',
                  }[plan]}
                >
                  {plan}
                </Tag>
              )}
            />
            <Column
              title={(
                <Tooltip title="Searches">
                  <Icon type="search" />
                </Tooltip>
              )}
              dataIndex="searches"
              key="searches"
            />
            <Column
              title={(
                <Tooltip title="Influencers Profile Viewed">
                  <Icon type="eye" />
                </Tooltip>
              )}
              dataIndex="actions"
              key="actions"
              render={actions => actions.VIEW_PROFILE}
            />
            <Column
              title={(
                <Tooltip title="Influencers Emails Showed">
                  <Icon type="mail" />
                </Tooltip>
              )}
              dataIndex="actions"
              key="actions2"
              render={actions => actions.SHOW_CONTACT_INFOS}
            />
            <Column
              title={(
                <Tooltip title="Influencers Added">
                  <Icon type="user" />
                </Tooltip>
              )}
              dataIndex="influencers"
              key="influencers"
            />
            <Column
              title={(
                <Tooltip title="Campaigns">
                  <Icon type="unordered-list" />
                </Tooltip>
              )}
              dataIndex="campaigns"
              key="campaigns"
            />
            <Column
              title="Created At"
              dataIndex="createdAt"
              key="createdAt"
              render={createdAt => `${moment(createdAt).format('YYYY/MM/DD @ HH:mm')}`}
            />
            <Column title="source" dataIndex="source" key="source" />
          </Table>
          <Pagination
            size="small"
            disabled={this.state.loading}
            total={this.state.count}
            showSizeChanger
            current={parseInt(page || '1', 10)}
            onChange={this.onPageChange}
            onShowSizeChange={this.onPageSizeChange}
            pageSize={parseInt(limit || '20', 10)}
            showQuickJumper
            showTotal={total => `Total ${total} users`}
          />
        </div>

      </div>
    );
  }
}

Customers.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Customers;
