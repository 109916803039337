import React from 'react';
import {
  Button, Icon, Menu, message,
} from 'antd';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthService from '../../services/auth.service';
import Users from './users';
import Affiliates from './affiliates';
import UserDetails from './users/user-details';
import './index.css';
import FullScreenLoader from '../../components/full-screen-loader';

const ROUTES = [
  {
    path: '/users/',
    menuHref: '/users',
    selectedFragment: '/users',
    name: 'Users',
    icon: 'user',
    component: Users,
    show: () => true,
  },
  {
    path: '/users/:userId',
    component: UserDetails,
    show: () => false,
  },
  {
    path: '/affiliates/',
    menuHref: '/affiliates',
    selectedFragment: '/affiliates',
    name: 'Affiliates',
    icon: 'team',
    component: Affiliates,
    show: () => true,
  },
];

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      admin: null,
      loading: true,
      currentState: {},
    };
  }

  componentDidMount() {
    this.getProfile();
  }

  getProfile = () => {
    this.setState({ loading: true }, () => {
      AuthService.getProfile()
        .then((admin) => {
          this.setState({ admin, loading: false });
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 401) {
            localStorage.clear();
            // eslint-disable-next-line react/prop-types
            this.props.history.push(`/login?next=${encodeURIComponent(window.location.hash)}`);
            this.setState({ loading: false });
          } else {
            message.error("Erreur lors de la récupération des infos de l'administrateur.");
          }
        });
    });
  };

  logout = () => {
    AuthService.logout()
      .then(() => null)
      .catch(() => null);
    localStorage.clear();
    // eslint-disable-next-line react/prop-types
    this.props.history.push('/login');
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const currentPath = this.props.location.pathname;
    const selectedKeys = ROUTES.filter(
      route => currentPath.indexOf(route.selectedFragment || route.path) !== -1,
    ).map(route => route.path);
    return (
      <div id="App">
        {this.state.admin && <div style={{ display: 'none' }}>{this.state.admin.email}</div>}
        <div id="AppHeader">
          <img src="img/logo.png" alt="logo jeffrey" style={{ maxWidth: '38px' }} />
          <span className="LogoText">Admin</span>
          <Menu mode="horizontal" selectedKeys={selectedKeys}>
            {ROUTES.filter(r => r.show(this.state)).map(route => (
              <Menu.Item key={route.path}>
                <a href={`#/app${route.menuHref || route.path}`}>
                  <Icon type={route.icon} />
                  <span className="Text">{route.name}</span>
                </a>
              </Menu.Item>
            ))}
          </Menu>
          <div className="Flex1" />
          <Button icon="logout" shape="round" onClick={this.logout}>
            Log out
          </Button>
        </div>

        <div id="AppContainer">
          <Switch>
            {ROUTES.map(route => (
              <Route
                key={route.path}
                path={`/app${route.path}`}
                render={props => (this.state.loading ? (
                  <FullScreenLoader />
                ) : (
                  <route.component {...props} {...this.state} />
                ))
                }
                exact
              />
            ))}
            <Redirect from="*" to={`/app${ROUTES[0].path}`} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;
