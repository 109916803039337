export const API_URL = 'https://api.tokfluence.com/admins';

export const PLANS = {
  FREE: 'Free',
  STARTUP: 'Startup',
  STARTER: 'Starter (Deprecated)',
  BUSINESS: 'Business (Deprecated)',
  BUSINESS_V2: 'Business v2 (Deprecated, 299$)',
  BUSINESS_V3: 'Business v3 (129$)',
  GOLD: 'Gold (Deprecated)',
};
