export function parseSearch(search) {
  return search
    .substr(1, Infinity) // Remove ?
    .split('&') // Split every fragment
    // Reduce
    .reduce((prev, fragment) => {
      const [key, value] = fragment.split('=');
      // eslint-disable-next-line no-param-reassign
      if (key) prev[key] = decodeURIComponent(value);
      return prev;
    }, {});
}

export function stringifySearch(obj) {
  return `?${Object.keys(obj).map(key => `${key}=${obj[key]}`).join('&')}`;
}

export function escapeRegExp(str) {
  // eslint-disable-next-line no-useless-escape
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}
