/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { message } from 'antd';
import moment from 'moment';
import 'moment/locale/fr';
import './index.css';
import App from './App';

moment.locale('fr');
message.config({
  top: 64,
});

ReactDOM.render(<App />, document.getElementById('root'));
