import api from './api.service';

export default {
  login(email, password) {
    return api.call('/login', { email, password });
  },
  getProfile() {
    return api
      .call('/get-profile')
      .then(admin => ({
        whiteLabelParams: {},
        ...admin,
      }));
  },
  logout() {
    return api.call('/logout');
  },
  forgetPassword(email) {
    return api.call('/forget-password', { email });
  },
  resetPassword(resetPasswordToken, password) {
    return api.call('/reset-password', { resetPasswordToken, password });
  },
};
