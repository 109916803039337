import React from 'react';
import {
  Button, Form, Icon, Input, message, Card,
} from 'antd';
import './index.css';
import AuthService from '../../services/auth.service';

class ForgetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      loading: false,
    };
  }

  componentDidMount() {
    if (localStorage.getItem('sessionid')) {
      // eslint-disable-next-line react/prop-types
      this.props.history.push('/app');
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.loading) return;
    this.setState({ loading: true }, () => {
      AuthService
        .forgetPassword(this.state.email)
        .then(() => {
          this.setState({ loading: false, email: '' });
          message.success('A mail has been sent with the instructions.');
        })
        .catch((error) => {
          this.setState({ loading: false });
          if (error && error.response && error.response.status === 404) {
            message.error('No account found with the provided email.');
          } else {
            message.error('Unknown error.');
          }
        });
    });
  };

  handleChange = name => (event) => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    return (
      <div id="LoginPage">
        <Card title="Admin Panel">
          <Form onSubmit={this.handleSubmit} className="LoginForm">
            <Form.Item>
              <Input
                prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Email"
                disabled={this.state.loading}
                type="email"
                required
                onChange={this.handleChange('email')}
              />
            </Form.Item>
            <Form.Item style={{ textAlign: 'center' }}>
              <Button type="primary" htmlType="submit" loading={this.state.loading}>
                Send Reset Link
              </Button>
              <a className="Login_forgot_link" href="#/forget-password">
                log in
              </a>
            </Form.Item>
          </Form>
        </Card>
      </div>
    );
  }
}

export default ForgetPassword;
