import React from 'react';
import {
  Button, Card, Form, Icon, Input, message,
} from 'antd';
import './index.css';
import AuthService from '../../services/auth.service';
import { parseSearch } from '../../utils';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      loading: false,
    };
  }

  componentDidMount() {
    if (localStorage.getItem('sessionid')) {
      // eslint-disable-next-line react/prop-types
      this.props.history.push('/app');
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.loading) return;
    this.setState({ loading: true }, () => {
      AuthService
        .login(this.state.email, this.state.password)
        .then((data) => {
          localStorage.setItem('sessionid', data.sessionId);
          // eslint-disable-next-line react/prop-types
          const { next } = parseSearch(this.props.location.search);
          // eslint-disable-next-line react/prop-types
          this.props.history.push(next ? decodeURIComponent(next).substr(1, Infinity) : '/app');
        })
        .catch((err) => {
          this.setState({ loading: false });
          if (err && err.response && err.response.status === 404) {
            message.error('Erreur lors du login : cet email n\'existe pas.');
          } else if (err && err.response && err.response.status === 401) {
            message.error('Erreur lors du login : mauvais mot de passe.');
          } else {
            message.error('Erreur inconnue.');
          }
        });
    });
  };

  handleChange = name => (event) => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    return (
      <div id="LoginPage">
        <Card title="Admin Panel">
          <Form onSubmit={this.handleSubmit} className="LoginForm">
            <Form.Item>
              <Input
                prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Email"
                disabled={this.state.loading}
                type="email"
                required
                onChange={this.handleChange('email')}
              />
            </Form.Item>
            <Form.Item>
              <Input.Password
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Password"
                disabled={this.state.loading}
                required
                onChange={this.handleChange('password')}
              />
            </Form.Item>
            <Form.Item style={{ textAlign: 'center' }}>
              <Button type="primary" htmlType="submit" loading={this.state.loading}>
                Log in
              </Button>
              <a className="Login_forgot_link" href="#/forget-password">
                forgot password
              </a>
            </Form.Item>
          </Form>
        </Card>
      </div>
    );
  }
}

export default Login;
