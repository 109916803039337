import React from 'react';
import {
  HashRouter, Redirect, Route, Switch,
} from 'react-router-dom';
import Login from './pages/login';
import ForgetPassword from './pages/login/forget-password';
import ResetPassword from './pages/login/reset-password';
import App from './pages/app';

function Routing(appProps) {
  return (
    <HashRouter>
      <Switch>
        <Route path="/login" exact render={props => <Login {...appProps} {...props} />} />
        <Route path="/forget-password" exact render={props => <ForgetPassword {...appProps} {...props} />} />
        <Route
          path="/reset-password/:resetPasswordToken"
          exact
          render={props => <ResetPassword {...appProps} {...props} />}
        />
        <Route path="/app" render={props => <App {...appProps} {...props} />} />
        <Redirect from="*" to="/login" />
      </Switch>
    </HashRouter>
  );
}

export default Routing;
