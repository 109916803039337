import api from './api.service';

export default {
  getAffiliatesStats() {
    return api.call('/get-affiliates-stats');
  },
  connectAs(affiliateId) {
    return api.call('/connect-as-affiliate', { affiliateId });
  },
};
