import React from 'react';
import { Button, message, Table } from 'antd';
import FullScreenLoader from '../../../components/full-screen-loader';
import AffiliatesService from '../../../services/affiliates.service';
import './index.css';


export default class EmailingPage extends React.Component {
  COLUMNS = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Referral',
      dataIndex: 'users',
      key: 'users',
    },
    {
      title: 'Plans',
      render: record => (
        <>
          {`FREE=${record.plans.FREE} STARTER=${record.plans.STARTER} BUSINESS=${record.plans.BUSINESS}`}
        </>
      ),
    },
    {
      title: 'Kitty',
      render: record => (
        <>
          {`${record.totalAmount - record.paid}$`}
        </>
      ),
    },
    {
      title: 'Actions',
      render: record => (
        <Button type="primary" size="small" onClick={() => this.connectAsAffiliate(record._id)}>Connect</Button>),
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      affiliates: [],
    };
  }

  componentDidMount() {
    AffiliatesService
      .getAffiliatesStats()
      .then(affiliates => this.setState({ affiliates, loading: false }))
      .catch((e) => {
        console.error(e);
        message.error('Erreur lors du chargement des affiliés');
        this.setState({ loading: false });
      });
  }

  connectAsAffiliate = (id) => {
    AffiliatesService
      .connectAs(id)
      .then(({ sessionId }) => {
        window.open(`https://affiliates.tokfluence.com/#/connect-as/${sessionId}`);
      })
      .catch(() => {
        message.error('Erreur lors du connect as.');
      });
  };

  render() {
    const { affiliates } = this.state;

    return (
      <div id="AffiliatesPage">
        {affiliates && affiliates.length > 0 && (
          <div className="Affiliates_list_wrapper">
            <div className="Affiliates_list_inner">
              <Table
                size="small"
                dataSource={affiliates}
                rowKey={record => record._id}
                columns={this.COLUMNS}
                pagination={false}
              />
            </div>
          </div>
        )}

        {this.state.loading && <FullScreenLoader />}
      </div>
    );
  }
}
