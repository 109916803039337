import api from './api.service';

export default {
  list(query = {}) {
    return api.call('/list-users', {
      page: query.page || 0,
      limit: query.limit || 20,
      filter: query.filter || {},
    });
  },
  getUserDetails(userId) {
    return api.call('/get-user-details', { userId });
  },
  connectAs(userId) {
    return api.call('/connect-as-user', { userId });
  },
  updateUser(userId, obj) {
    return api.call('/update-user', {
      userId,
      ...obj,
    });
  },
  deleteUser(userId) {
    return api.call('/delete-user', { userId });
  },
};
