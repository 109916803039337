/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import * as Antd from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import FullScreenLoader from '../../../../components/full-screen-loader';
import UsersService from '../../../../services/users.service';
import './index.css';
import { PLANS } from '../../../../constants';

const {
  Col,
  Card,
  Alert,
  Avatar,
  Typography,
  Button,
  Row,
  Select,
  Empty,
  List,
  Skeleton,
  message,
  Modal,
  Input,
  Icon,
  Form,
} = Antd;

class UserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      user: null,
      showUpdateModal: false,
      showDeleteModal: false,
    };
    this.EmailRef = React.createRef();
    this.PlanRef = React.createRef();
    this.DeleteRef = React.createRef();
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    const { userId } = this.props.match.params;
    const { userId: prevUserId } = prevProps.match.params;
    if (userId !== prevUserId) {
      this.fetch();
    }
  }

  fetch = () => {
    this.setState({ loading: true }, () => {
      const { userId } = this.props.match.params;
      UsersService
        .getUserDetails(userId)
        .then(user => this.setState({ loading: false, user }))
        .catch(() => null);
    });
  };

  connectAs = () => {
    UsersService
      .connectAs(this.props.match.params.userId)
      .then(({ sessionId }) => {
        window.open(`https://app.tokfluence.com/#/connect-as/${sessionId}`);
      })
      .catch(() => {
        message.error('Error while trying to connect to user account.');
      });
  };

  renderSearchArg = (arg) => {
    if (Array.isArray(arg)) return arg.join(',');
    return JSON.stringify(arg);
  };

  handleUpdate = (e) => {
    e.preventDefault();
    if (this.state.loading) return;
    this.setState({ loading: true });
    UsersService
      .updateUser(this.props.match.params.userId, {
        email: this.EmailRef.current.input.value,
        plan: this.PlanRef.current.rcSelect.state.value[0],
      })
      .then(() => {
        this.setState({ loading: false, showUpdateModal: false });
        this.fetch();
      })
      .catch(() => {
        message.error('Error while updating user');
        this.setState({ loading: false });
      });
  };

  handleDelete = (e) => {
    e.preventDefault();
    if (this.DeleteRef.current.input.value !== 'DELETE') {
      message.error('You should enter DELETE to confirm');
      return;
    }
    if (this.state.loading) return;
    this.setState({ loading: true });
    UsersService
      .deleteUser(this.props.match.params.userId)
      .then(() => {
        /* eslint-disable react/prop-types */
        if (this.props.history.length > 1) {
          this.props.history.goBack();
        } else {
          this.props.history.push('/app/users');
        }
        /* eslint-enable react/prop-types */
      })
      .catch(() => {
        message.error('Error while updating user');
        this.setState({ loading: false });
      });
  };

  render() {
    const { user } = this.state;
    if (!user) return <FullScreenLoader />;
    return (
      <div className="Container_custom">
        {this.state.loading && <FullScreenLoader />}
        <div id="UserDetails" className="CustomerDetails">
          <Row gutter={12}>
            <Col lg={8} md={12}>
              <Card size="small" className="TextAlignCenter UserProfilePage">
                <Avatar icon="user" size={80} />
                <div>
                  <div>
                    <span style={{ fontSize: 10 }}>{user.emailValidated ? '✅ ' : '❌ '}</span>
                    <Typography.Text level={4} copyable code style={{ wordBreak: 'break-all' }}>
                      {user.email}
                    </Typography.Text>
                  </div>
                  <br />
                  {(user.firstName || user.lastName) && (
                    <>
                      <div>
                        <u>Full Name:</u>
                        {` ${user.firstName || ''} ${user.lastName || ''}`}
                      </div>
                    </>
                  )}
                  {user.companyName && (
                    <>
                      <div>
                        <u>Company Name:</u>
                        {` ${user.companyName}`}
                      </div>
                    </>
                  )}
                  {user.goal && (
                    <>
                      <div>
                        <u>Goal:</u>
                        {` ${user.goal}`}
                      </div>
                    </>
                  )}
                  {user.ipInfo && (
                    <div className="IpInfo">
                      {JSON
                        .stringify(user.ipInfo, null, 4)
                        .split('\n')

                        .map((l, index) => (
                          <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            dangerouslySetInnerHTML={{ __html: l.replace(/ /g, '&nbsp;') }}
                          />
                        ))}
                    </div>
                  )}
                  <br />
                  <Alert message={`${PLANS[user.plan] || `${user.plan}?`} Plan`} />
                  <br />
                  <div>
                    <Button
                      ghost
                      type="primary"
                      icon="edit"
                      onClick={() => this.setState({ showUpdateModal: true })}
                    >
                      Update Profile
                    </Button>
                  </div>
                </div>
                <br />
                <div className="TextAlignCenter">
                  <Button type="primary" onClick={this.connectAs}>
                    Connect As
                  </Button>
                  &nbsp;
                  <Button type="danger" onClick={() => this.setState({ showDeleteModal: true })}>
                    Delete
                  </Button>
                </div>
              </Card>
            </Col>
            <Col lg={8} md={12}>
              {user.searches && (
                <Card size="small">
                  <Typography.Title level={2} className="TextAlignCenter">
                    {`Searches (${user.searches.length})`}
                  </Typography.Title>
                  {user.searches.length === 0 && <Empty />}
                  {user.searches.length > 0 && (
                    <List
                      dataSource={user.searches}
                      renderItem={({ args, createdAt }) => (
                        <List.Item>
                          <Skeleton title={false} loading={false} active>
                            <List.Item.Meta
                              title={Object.keys(args).map(key => `${key}=${this.renderSearchArg(args[key])}`).join(' ')}
                              description={`${moment(createdAt).format('YYYY/MM/DD @ HH:mm')}`}
                            />
                          </Skeleton>
                        </List.Item>
                      )}
                    />
                  )}
                </Card>
              )}
            </Col>
            <Col lg={8} md={12}>
              {user.campaigns && (
                <Card size="small">
                  <Typography.Title level={2} className="TextAlignCenter">
                    {`Campaigns (${user.campaigns.length})`}
                  </Typography.Title>
                  {user.campaigns.length === 0 && <Empty />}
                  {user.campaigns.length > 0 && (
                    <List
                      className="Tiktok_accounts_list"
                      itemLayout="horizontal"
                      dataSource={user.campaigns}
                      renderItem={item => (
                        <List.Item>
                          <Skeleton title={false} loading={false} active>
                            <List.Item.Meta
                              title={item.name}
                              description={Object.keys(item.stage).map(key => `${key}=${item.stage[key]}`).join(', ')}
                            />
                          </Skeleton>
                        </List.Item>
                      )}
                    />
                  )}
                </Card>
              )}
            </Col>
          </Row>
          <Modal
            title="Enter the new Email"
            visible={this.state.showUpdateModal}
            onCancel={() => this.setState({ showUpdateModal: false })}
            footer={null}
          >
            <form onSubmit={this.handleUpdate} id="UpdateForm">
              <Form.Item>
                <Input
                  placeholder="Email"
                  prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="email"
                  defaultValue={user.email}
                  required
                  ref={this.EmailRef}
                />
              </Form.Item>
              <Form.Item>
                <Select
                  defaultValue={user.plan}
                  required
                  ref={this.PlanRef}
                >
                  {Object.keys(PLANS).map(key => (
                    <Select.Option value={key} key={key}>{PLANS[key]}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'flex-end' }}>
                <Button type="primary" htmlType="submit" loading={this.state.loading}>
                  Update
                </Button>
              </div>
            </form>
          </Modal>
          <Modal
            title="Delete confirm"
            visible={this.state.showDeleteModal}
            onCancel={() => this.setState({ showDeleteModal: false })}
            footer={null}
          >
            <form onSubmit={this.handleDelete} id="UpdateForm">
              <Form.Item>
                <Input
                  placeholder="type DELETE to confirm"
                  type="text"
                  required
                  ref={this.DeleteRef}
                />
              </Form.Item>
              <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'flex-end' }}>
                <Button type="danger" htmlType="submit" loading={this.state.loading}>
                  Confirm
                </Button>
              </div>
            </form>
          </Modal>
        </div>
      </div>
    );
  }
}

UserDetails.propTypes = {
  match: PropTypes.object.isRequired,
};
export default UserDetails;
