import axios from 'axios';
import { API_URL } from '../constants';

export default {
  call(url, body = {}) {
    return axios({
      method: 'POST',
      url: `${API_URL}${url}`,
      data: body,
      headers: {
        sessionid: localStorage.getItem('sessionid'),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then(({ data }) => data);
  },
};
