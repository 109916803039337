import React from 'react';
import {
  Button, Card, Form, Icon, Input, message,
} from 'antd';
import './index.css';
import AuthService from '../../services/auth.service';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordConfirmation: '',
      loading: false,
    };
  }

  componentDidMount() {
    if (localStorage.getItem('sessionid')) {
      // eslint-disable-next-line react/prop-types
      this.props.history.push('/app');
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.loading) return;
    if (this.state.password !== this.state.passwordConfirmation) {
      message.error('Passwords mismatch');
      return;
    }
    this.setState({ loading: true }, () => {
      AuthService
      // eslint-disable-next-line react/prop-types
        .resetPassword(this.props.match.params.resetPasswordToken, this.state.password)
        .then((data) => {
          message.success('Successfully updated password.');
          localStorage.setItem('sessionid', data.sessionId);
          // eslint-disable-next-line react/prop-types
          this.props.history.push('/app');
        })
        .catch((err) => {
          this.setState({ loading: false });
          if (err && err.response && err.response.status === 404) {
            message.error('The link to reset password has expired.');
          } else {
            message.error('Unknown error.');
          }
        });
    });
  };

  handleChange = name => (event) => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    return (
      <div id="LoginPage">
        <Card title="Admin Panel">
          <Form onSubmit={this.handleSubmit} className="LoginForm">
            <Form.Item>
              <Input.Password
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Password"
                disabled={this.state.loading}
                required
                onChange={this.handleChange('password')}
              />
            </Form.Item>
            <Form.Item>
              <Input.Password
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Password (confirmation)"
                disabled={this.state.loading}
                required
                onChange={this.handleChange('passwordConfirmation')}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={this.state.loading}>
                Update Password
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    );
  }
}

export default ResetPassword;
